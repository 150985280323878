import Logo from './assets/logo.svg';
import LogoIVPT from './assets/ivpt-logo.svg';

import Slide1 from './assets/slides/1.jpg';

import Speeches from './assets/speeches.jpg';
import Theaters from './assets/theaters.jpg';
import Musical from './assets/musical.jpg';
import Books from './assets/books.jpg';

import { getRandomVideoCode } from './utils/videos';

const App = () => (
  <>
    <header className="bg-[url(assets/background-header.jpg)] bg-cover bg-no-repeat bg-center">
      <section className="h-14">
        <section className="flex items-center justify-center space-x-4 mx-auto h-full w-full lg:w-3/5 lg:justify-between lg:space-x-0">
          <a href="http://institutovidaparatodos.org.br/" target="_blank" className="block transition ease-in-out hover:opacity-70" rel="noreferrer">
            <img src={LogoIVPT} width="121" height="30" alt="" />
          </a>
          <section className="flex space-x-4">
            <a href="https://www.instagram.com/institutovidaparatodos/" className="text-white transition ease-in-out hover:opacity-70" target="_blank" title="Abrir instagram Expo Livro" rel="noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-instagram">
                <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
              </svg>
            </a>
            <a href="https://www.facebook.com/institutovidaparatodos/" className="text-white transition ease-in-out hover:opacity-70" target="_blank" title="Abrir facebook Expo Livro" rel="noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-facebook">
                <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
              </svg>
            </a>
          </section>
        </section>
      </section>
      <section className="flex justify-center bg-gradient-to-b from-transparent to-primary pt-14 pb-24">
        <section className="flex justify-between items-center w-3/5 flex-col lg:flex-row">
          <a href="/" className="block transition ease-in-out hover:opacity-70 mb-6 lg:mb-0">
            <img src={Logo} width="177" height="130" alt="" />
          </a>

          <nav className="flex items-center list-none -mx-4 -mt-4 text-center lg:text-left">
            <li className="mx-4">
              <a href="/" className="text-white font-semibold py-2 transition ease-in-out border-b-2 border-b-transparent hover:border-b-secondary">
                Nossa História
              </a>
            </li>
            <li className="mx-4">
              <a href="/" className="text-white font-semibold py-2 transition ease-in-out border-b-2 border-b-transparent hover:border-b-secondary">
                Carretas
              </a>
            </li>
            <li className="mx-4">
              <a href="/" className="text-white font-semibold py-2 transition ease-in-out border-b-2 border-b-transparent hover:border-b-secondary">
                Atividades
              </a>
            </li>
            <li className="mx-4">
              <a href="/" className="text-white font-semibold py-2 transition ease-in-out border-b-2 border-b-transparent hover:border-b-secondary">
                Fale Conosco
              </a>
            </li>
          </nav>
        </section>
      </section>
    </header>
    <section className="flex flex-col space-y-12">
      <section className="bg-white flex flex-col p-14 mx-auto -mt-12 space-y-6 rounded-xl shadow-lg w-full lg:w-3/5">
        <h1 className="text-primary text-2xl uppercase font-semibold border-l-4 border-primary pl-4">
          O que é o <span className="block text-secondary font-black">Expolivro</span>
        </h1>
        <section className="flex flex-col space-y-4">
          <p className="text-primary text-base">
            O Expolivro é um projeto de caráter cultural, social e educacional que visa estimular o hábito da leitura saudável. Cremos que a leitura da Bíblia e de livros espirituais é capaz de transformar a vida das pessoas e, consequentemente, das famílias e da sociedade.
          </p>
          <p className="text-primary text-base">
            O Expolivro tem o objetivo de levar as pessoas a conhecerem o plano que Deus tem para as suas vidas.
          </p>
          <p className="text-primary text-base">
            Nosso sentimento é o sentimento de Deus: salvar as pessoas, para que o aflito encontre paz, o perdido seja achado e salvo e os dispersos reunidos. Que todos possam ouvir o chamado de Deus, encontrá-Lo e ser grandemente abençoados.
          </p>
        </section>
      </section>
      <section className="flex mx-auto w-full flex-col lg:w-3/5 lg:flex-row">
        <section>
          <section className="flex w-full h-96 rounded-xl">
            <img src={Slide1} alt="" className="mx-auto rounded-xl" />
          </section>
        </section>
        <section className="flex-1 ml-14 pr-14 pt-7 space-y-6">
          <h2 className="text-primary text-xl uppercase font-semibold border-l-4 border-primary pl-4">
            Nossa <span className="block text-secondary font-black">Missão</span>
          </h2>
          <section className="flex flex-col space-y-4">
            <p className="text-primary text-base">
              Dados do Instituto Pró-Livro de 2021: "O brasileiro lê em média 5 livros por ano" e "44% da população não pratica o hábito da leitura", nos motivou a restaurar, de 1991, o projeto EXPOLIVRO com a missão de despertar, nas pessoas, a paixão pela leitura. Incentivar o hábito da leitura contribui diretamente com o desenvolvimento cultural e social. Mas queremos ir além. Em 1998, a OMS definiu que "saúde é um estado de completo bem-estar físico, mental, ESPIRITUAL e social."
            </p>

            <a href="/" className="w-32 flex border-2 justify-center items-center border-primary color-primary p-2 font-semibold rounded-lg hover:bg-primary hover:text-white">
              Saiba mais
            </a>
          </section>
        </section>
      </section>
      <section className="flex mx-auto w-full flex-col space-y-12 lg:w-3/5">
        <h2 className="bg-white text-primary text-xl uppercase font-semibold border-l-4 border-primary pl-4">
          Atividades gratuitas <span className="block text-secondary font-black">para toda a família</span>
        </h2>
        <section className="flex items-center justify-between space-x-6">
          <article className="bg-white shadow-lg rounded-lg p-4 flex flex-col justify-center items-center space-y-4">
            <img src={Speeches} width="240" height="240" className="rounded-lg" alt="" />
            <h3 className="text-primary text-base font-semibold">Palestras</h3>
          </article>
          <article className="bg-white shadow-lg rounded-lg p-4 flex flex-col justify-center items-center space-y-4">
            <img src={Theaters} width="240" height="240" className="rounded-lg" alt="" />
            <h3 className="text-primary text-base font-semibold">Teatro</h3>
          </article>
          <article className="bg-white shadow-lg rounded-lg p-4 flex flex-col justify-center items-center space-y-4">
            <img src={Musical} width="240" height="240" className="rounded-lg" alt="" />
            <h3 className="text-primary text-base font-semibold">Música</h3>
          </article>
          <article className="bg-white shadow-lg rounded-lg p-4 flex flex-col justify-center items-center space-y-4">
            <img src={Books} width="240" height="240" className="rounded-lg" alt="" />
            <h3 className="text-primary text-base font-semibold">Livros</h3>
          </article>
        </section>
      </section>
      <section></section>
    </section>
    <section className="bg-primary bg-opacity-5 py-12">
      <section className="mx-auto bg-white rounded-lg shadow-lg overflow-hidden w-full items-center justify-center lg:w-3/5">
        <section className="aspect-w-16 aspect-h-9">
          <iframe
            src={`https://www.youtube.com/embed/${getRandomVideoCode}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            title="Expolivro entrevistas"
            allowFullScreen
          />
        </section>
      </section>
    </section>
    <section className="text-center pt-12 px-12">
      <section className="flex flex-col m-auto w-full space-y-4 lg:w-3/6">
        <p className="text-primary font-semibold text-base">
          "A carreta expolivro esteve aqui em Paço do Lumiar, Maranhão! Que lindo projeto! Meus filhos amaram e eu também! Parabéns por este ministério tão lindo e abençoado!"
        </p>
        <span className="text-primary text-sm italic">
          José Carlos Sousa
        </span>
      </section>
    </section>
    <footer className="flex justify-center bg-primary p-10 mt-24 flex-col items-center lg:flex-row">
      <section className="flex justify-between items-center w-3/5 flex-col lg:flex-row">
        <a href="/" className="block transition ease-in-out opacity-70 hover:opacity-100 mb-6 lg:mb-0">
          <img src={Logo} width="100" height="75" alt="" />
        </a>

        <nav className="flex items-center list-none text-center lg:text-left lg:mr-12">
          <li className="mx-4">
            <a href="/" className="text-white font-semibold py-2 transition ease-in-out border-b-2 border-b-transparent hover:border-b-secondary">
              Nossa História
            </a>
          </li>
          <li className="mx-4">
            <a href="/" className="text-white font-semibold py-2 transition ease-in-out border-b-2 border-b-transparent hover:border-b-secondary">
              Carretas
            </a>
          </li>
          <li className="mx-4">
            <a href="/" className="text-white font-semibold py-2 transition ease-in-out border-b-2 border-b-transparent hover:border-b-secondary">
              Atividades
            </a>
          </li>
          <li className="mx-4">
            <a href="/" className="text-white font-semibold py-2 transition ease-in-out border-b-2 border-b-transparent hover:border-b-secondary">
              Fale Conosco
            </a>
          </li>
        </nav>
      </section>
      <section className="flex space-x-4">
        <a href="https://www.instagram.com/expolivro_/" className="text-white transition ease-in-out hover:opacity-70" target="_blank" title="Abrir instagram Expo Livro" rel="noreferrer">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-instagram">
            <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
            <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
            <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
          </svg>
        </a>
        <a href="https://www.facebook.com/expolivro/" className="text-white transition ease-in-out hover:opacity-70" target="_blank" title="Abrir facebook Expo Livro" rel="noreferrer">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-facebook">
            <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
          </svg>
        </a>
      </section>
    </footer>
  </>
);

export default App;
